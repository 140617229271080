import { useEffect, useState } from "react";
import { helpCenterUrl } from "../assets/data/urls";
/* Animate On Scroll Library */
import AOS from "aos";
import "aos/dist/aos.css";
/* Components */
import Hero from "../components/Hero";
import Card from "../components/Card";
import Fact from "../components/Fact";
import CardCommunity from "../components/CardCommunity";
import Video from "../components/Video";
/* Data */
import DATAHUB_SERVICES from "../assets/data/services";
import COMMUNITY from "../assets/data/community";
/* IMG */
import Dropzone from "../assets/img/dropzone.svg";
import Collaborate from "../assets/img/data collaboration-2.svg";
import Team from "../assets/img/projects.svg";
import Spacer from "../assets/svg/spacer.svg";
/* Blog */
import SummarySection from "../components/blog/SummarySection";

function Landing() {
  const [amountOfData, setAmountOfData] = useState(330);
  const [amountOfProjects, setamountOfProjects] = useState(200);
  const [amountOfPlans, setAmountOfPlans] = useState(550);

  useEffect(() => {
    const getApiData = () => {
      fetch(
        `${helpCenterUrl}/help_backend/confluence_documents/excerpts/label/dh_home_fact`
      )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw new Error("Server Error");
          }
        })
        .then((result) => {
          const dataAmount = result.find(
            (element) => element.title === "data_amount"
          ).excerpt;
          const projectsAmount = result.find(
            (element) => element.title === "projects_amount"
          ).excerpt;
          const plansAmount = result.find(
            (element) => element.title === "plans_amount"
          ).excerpt;

          const isNumeric = (str) => !isNaN(str) && !isNaN(parseFloat(str));

          if (dataAmount && isNumeric(dataAmount)) {
            setAmountOfData(dataAmount);
          }

          if (projectsAmount && isNumeric(projectsAmount)) {
            setamountOfProjects(projectsAmount);
          }

          if (plansAmount && isNumeric(plansAmount)) {
            setAmountOfPlans(plansAmount);
          }
        })
        .catch((err) => {
          console.log("An error has occurred fetching API data: " + err);
        });
    };

    getApiData();

    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Hero />
      <section id="services">
        <div className="section__content">
          <h3 data-aos="fade-right">
            Our solutions for better research data management
          </h3>
          <div className="card__container">
            {DATAHUB_SERVICES.map((service) => (
              <Card
                id={service.id}
                key={service.key}
                title={service.title}
                description={service.description}
                icon={service.icon}
                link={service.link}
                linkTitle={service.linkTitle}
              />
            ))}
          </div>
        </div>
        <div className="custom-shape-divider-bottom">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </section>

      <section id="fair-principles">
        <div className="section__content">
          <Video />
        </div>
      </section>

      <section id="about">
        <div className="custom-shape-divider-top">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        <div className="section__content">
          <img className="dropzone" src={Dropzone} alt="Dropzone" />
          <h2 data-aos="fade-right">About us</h2>
          <div className="flex">
            <div>
              <p>
                At DataHub, we provide data management services for studies in
                Maastricht UMC+ and Maastricht University. Our role is that of a
                data broker who enables the reuse of data by researchers in the
                university, the hospital and beyond.
              </p>
              <p style={{ marginBottom: "none" }}>
                DataHub aims to ease the burden on the researcher with regard
                to data management and secure data storage, in order to save you
                time for doing where your heart lies: conducting research.
              </p>
            </div>
          </div>

          <div className="facts__container flex">
            <Fact
              endNumber={amountOfData}
              description={"TB of data stored in Maastricht Data Repository"}
            />
            <Fact
              endNumber={amountOfProjects}
              description={"Research projects supported"}
            />
            <Fact
              endNumber={amountOfPlans}
              description={"Data management plans in Data Portal"}
            />
          </div>
          <img className="team" src={Team} alt="Team" />
          <h3 data-aos="fade-right">Our Team</h3>
          <p style={{ marginBottom: "5vh" }}>
            Knowledge exchange and engagement are very important for being
            innovative and flexible for our research community. Our
            multidisciplinary DataHub team closely collaborates with colleagues
            from other departments. This close collaboration is embodied in the
            DataHub community. In our community, we highly value teamwork and
            openness.
          </p>
          <div className="community__cards flex" data-aos="fade-right">
            {COMMUNITY.map((person) => (
              <CardCommunity
                key={person.key}
                name={person.name}
                surname={person.surname}
                role={person.role}
                email={person.email}
                img={person.img}
                phone={person.phone}
                className={person.className}
              />
            ))}
          </div>
          <img className="collaborate" src={Collaborate} alt="Collaborate" />
        </div>
      </section>

      <section id="blog">
        <div
          className="spacer top"
          style={{ backgroundImage: `url(${Spacer})` }}
        ></div>
        <div className="section__content">
          <h3>
            Latest <strong>DataHub</strong> News and Insights
          </h3>
          <SummarySection />
        </div>
      </section>
    </>
  );
}

export default Landing;
